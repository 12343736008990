import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import * as React from "react";
import {Select, MenuItem, Container} from "@mui/material";
import {projectTypeMap, rootUrl} from "../Utilities/Utils";
import { withRouter } from "./withRouter";
import {ProjectService} from "../Services/ProjectService";
import AuthService from "../Services/AuthService";
import { withAuth0 } from "@auth0/auth0-react";
import {darktheme} from "../Utilities/MUIThemes";
import CssBaseline from "@mui/material/CssBaseline";
import AppHeader from "./AppHeader";
import {ThemeProvider} from "@mui/material/styles";
import {DefaultLoadingScreen} from "./DefaultLoadingScreen";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";

class ProjectCreate extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            type: '',
            name: '',
            projectType: 0,
            isLoadingData: false
        }
    }

    resetState() {
        this.setState({
            type: '',
            name: '',
            projectType: 0,
            isLoadingData: false
        })
    }

    handleProjectTypeChange(e) {
        this.setState({
            type: e.target.value
        });
    }

    handleProjectNameChange(e) {
        console.log("NAME", e.target.value);
        this.setState({
            name: e.target.value
        });
    }

    async handleSubmitNewProject(e) {
        e.preventDefault();

        this.setState({
            isLoadingData: true
        }, async () => {
            let token = await AuthService.getTokenSilently(this.props.auth0);

            let results = await ProjectService.create({
                name: this.state.name,
                type: this.state.type,
            }, token);

            this.resetState();

            this.showProjectListPage();
        });
    }

    showProjectListPage() {
        this.props.router.navigate(`/app/project/list`);
    }

    render() {

        if(!this.state.isLoadingData) {
            return (
                <ThemeProvider theme={darktheme}>
                    <CssBaseline/>
                    <Container
                        maxWidth={false}
                        sx={{
                            // backgroundColor: darktheme.palette.primary.main,
                            // height: "100vh"
                            paddingRight: 1,
                            paddingLeft: 1
                        }}
                    >
                        <AppHeader/>
                        {/*<div className="TodoWrapper">*/}
                        <Grid
                            spacing={4}
                            direction="row"
                            sx={{
                                display: 'grid',
                                marginTop: 10,
                                marginLeft: 0,
                                width: '100%'
                            }}
                        >
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignText: 'center'
                            }}>
                            <Typography>
                                <form onSubmit={(e, value) => this.handleSubmitNewProject(e)}>
                                    <Grid>
                                        <Grid item xs={4}>
                                            <Select
                                                fullWidth
                                                required
                                                id="text-field"
                                                value={this.state.type}
                                                defaultValue={this.state.projectType}
                                                onChange={((e) => this.handleProjectTypeChange(e))}
                                            >
                                                {Object.keys(projectTypeMap).map((key) => {
                                                    return (
                                                        <MenuItem value={key}>{projectTypeMap[key]}</MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <TextField
                                                fullWidth
                                                required
                                                id="outlined-basic"
                                                placeholder="Add Project Name"
                                                value={this.state.name}
                                                variant="outlined"
                                                onChange={((e) => this.handleProjectNameChange(e))}
                                            />
                                        </Grid>
                                    </Grid>
                                    <br />
                                    <br />
                                    <Button variant={"contained"} type="submit">Create Project</Button>
                                </form>
                            </Typography>
                            </Box>
                        </Grid>
                    </Container>
                </ThemeProvider>
            )
        } else {
            return (
                <DefaultLoadingScreen />
            )
        }
    }
}

export default withAuth0(withRouter(ProjectCreate));