import * as React from 'react';
import { MoodboardService } from '../Services/MoodboardService';
import TextField from '@mui/material/TextField';
import { withRouter } from './withRouter';
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import {SongService} from "../Services/SongService";

class MoodboardCreate extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            notes: '',
            songId: this.props.songId || '',
            projectId: this.props.projectId || '',
            isLoadingData: true
        }
    }

    async handleSubmit(e) {
        e.preventDefault();
        let moodboard = await MoodboardService.create({
            notes: this.state.notes
        });
        let song = await SongService.update({
            id: this.state.songId,
            moodboardId: moodboard.id
        });

        this.setState({
            isLoadingData: false
        });

        this.props.onSubmit();
    }

    handleNotesUpdate(e) {
        e.preventDefault();

        this.setState({
            notes: e.target.value
        });
    }

    render() {
        // @TODO IMPLEMENT
        return <div>
            Moodboard Create Page
            <form onSubmit={(e, value) => this.handleSubmit(e, value)}>
                <Grid container
                      spacing={4}
                      direction="row">
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            required
                            id="text-field"
                            placeholder="Enter Notes"
                            value={this.state.notes}
                            variant="outlined"
                            onChange={((e) => this.handleNotesUpdate(e))}
                        />
                    </Grid>
                </Grid>
                <br />
                <br />
                <Button variant={"contained"} type="submit">Submit Note</Button>
            </form>
        </div>
    }
}

export default withRouter(MoodboardCreate);