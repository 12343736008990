import React from "react";
import { Container, Box } from "@mui/material";
import Grid from '@mui/system/Unstable_Grid';
import styled from '@mui/system/styled';
import Typography from "@mui/material/Typography";
import { withRouter } from './withRouter';
import { Auth0Options } from '../Utilities/Utils';
import AuthService from '../Services/AuthService';

const Item = styled('div')(({ theme }) => ({
    textAlign: 'center',
}));

class TempLandingPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {}

    calculateNumDaysLeft() {
        let today = new Date();
        let launchDate = new Date("06/30/2024");
        let timeRemainingRaw = launchDate.getTime() - today.getTime();
        return timeRemainingRaw / (1000 * 60 * 60 * 24);
    }

    render() {
        return (
            <Container maxWidth={"sm"}>
                <Box height={"100vh"} sx={{ flexGrow: 1, margin: 20}}>
                    <Grid container spacing={2}>
                        <Grid xs={12} sm={4}>
                            <Item>SOUNDS & SIGHTS</Item>
                        </Grid>
                        <Grid xs={12} sm={4}>
                            {/*<Item>COMING SOON</Item>*/}
                        </Grid>
                        <Grid xs={12} sm={4}>
                            <Item>MAKE MUSIC YOU LOVE</Item>
                        </Grid>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <Grid xs={12}>
                            <Typography textAlign={"center"} variant={"h3"}>
                                there's something coming.
                            </Typography>
                        </Grid>
                        <Grid xs={12}>
                            <Typography textAlign={"center"} variant={"body1"}>
                                your flow state. reimagined.
                            </Typography>
                        </Grid>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <Grid xs={12}>
                            <br/>
                            <Item>
                                T - {Math.floor(this.calculateNumDaysLeft())} DAYS.
                            </Item>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        )
    }
}

export default withRouter(TempLandingPage);