import * as React from "react";
import {DateUtilities} from "../DateUtilities";
import SongVersionNote from "./SongVersionNote";
import AudioPlayer from "react-h5-audio-player";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import {SongVersionService} from "../Services/SongVersionService";
import {FileService} from "../Services/FileService";
import {SongVersionNoteService} from "../Services/SongVersionNoteService";
import CardMedia from "@mui/material/CardMedia";
import DefaultSongArtwork from '../assets/default-song-image-oluvo-purple.png';
import AuthService from "../Services/AuthService";
import { withAuth0 } from "@auth0/auth0-react";
import Box from "@mui/material/Box";

class SongDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            songVersion: this.props.version, // passed in - the info about each particular song version
            isLoadingData: true, // whether we're currently loading data right now
            latestVersionNotes: {
                notes: '',
                timestamp: '00:00'
            }, // notes textbox
            songAudioFileURL: ''
        }
    }

    handleNoteChange(e) {
        e.preventDefault();

        let previousTimeStamp = this.state.latestVersionNotes.timestamp;
        this.setState({
            latestVersionNotes: {
                notes: e.target.value,
                timestamp: previousTimeStamp
            }
        });
    }

    handleNoteTimeStampUpdate(e) {
        let previousNote = this.state.latestVersionNotes.notes;
        this.setState({
            latestVersionNotes: {
                notes: previousNote,
                timestamp: e.target.value
            }
        });
    }

    async fetchData() {

        this.setState({
            isLoadingData: true,
        });

        let token = await AuthService.getTokenSilently(this.props.auth0);

        let results = await SongVersionService.findOneById(this.state.songVersion.id, token);

        let presignedUrlForSong = await FileService.findOneById(this.state.songVersion.fileName, token);

        console.log("presignedUrlForSong", presignedUrlForSong);

        // let audioFileObject = await FileService.getAudioBlobURLFromAudioData(songFileData);

        this.setState({
            songAudioFileURL: presignedUrlForSong.signedURL,
            isLoadingData: false
        });

        return results[0];
    }

    async handleSubmitSongVersionNote(e) {
        e.preventDefault();

        let requestOptions = {
            songVersionId: this.state.songVersion.id,
            notes: this.state.latestVersionNotes.notes,
            timestamp: this.state.latestVersionNotes.timestamp
        };

        this.setState({
            isLoadingData: true
        });

        let token = await AuthService.getTokenSilently(this.props.auth0);

        let results = await SongVersionNoteService.create(requestOptions, token);

        console.log("SongDetail: handleSubmitSongVersionNote - results", results);

        let newlyFetchedData = await this.fetchData();

        this.setState({
            songVersion: newlyFetchedData,
            isLoadingData: false,
            latestVersionNotes: {
                notes: '',
                timestamp: ''
            }
        }, () => this.forceUpdate());
    }

    componentDidMount() {
        this.fetchData();
    }

    handlePauseEvent(e) {
        let timeStamp = e.target.currentTime;
        let minutesRaw = Math.floor(timeStamp / 60);
        let secondsRaw = Math.floor(timeStamp % 60);

        let minutes = minutesRaw < 10 ? `0${minutesRaw}` : String(minutesRaw);
        let seconds = secondsRaw < 10 ? `0${secondsRaw}` : String(secondsRaw);

        this.setState({
            latestVersionNotes: {
                notes: this.state.latestVersionNotes.notes,
                timestamp: `${minutes}:${seconds}`
            }
        });
    }

    render() {
        let style = {
            "background": `-webkit-linear-gradient(rgba(0, 0, 0, 0.9), rgba(50, 50, 50, 0.9)), url("https://img.freepik.com/free-photo/liquid-purple-art-painting-abstract-colorful-background-with-color-splash-paints-modern-art_1258-97771.jpg")`
        };
        let hasNotes = this.state.songVersion.songVersionNotes && this.state.songVersion.songVersionNotes.length > 0;
        if(!this.state.isLoadingData) {
            return (
                <Card sx={{
                    minWidth: 325,
                    minHeight: 300,
                    borderRadius: '10px'
                }}>
                    <CardContent>
                        <CardMedia
                            sx={{
                                height: 15,
                                cursor: 'pointer',
                                borderRadius: '5px'
                            }}
                            image={DefaultSongArtwork}
                            title="default song artwork">
                        </CardMedia>
                        <br/>
                        <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>
                            {DateUtilities.getDateDiffInDays(new Date(this.state.songVersion.createdTime), new Date())} Days
                            Ago
                        </Typography>

                        {/*<Typography variant={"body1"}>*/}
                        {/*    Revision Number: {this.state.songVersion.revisionNumber}*/}
                        {/*</Typography>*/}

                        <Typography variant={"body1"}>
                            FileName: {this.state.songVersion.fileName}
                        </Typography>

                        <Box marginTop={1}>
                            <Typography>
                                <div className="song-notes-container">
                                    {hasNotes ? this.state.songVersion.songVersionNotes.map((songVersionNote) => {
                                        return (
                                            <SongVersionNote
                                                key={songVersionNote.id}
                                                id={songVersionNote.id}
                                                notes={songVersionNote.notes}
                                                timestamp={songVersionNote.timestamp}
                                                completed={songVersionNote.completed}
                                            />
                                        )
                                    }) : <p>no notes</p>}
                                </div>
                            </Typography>
                        </Box>

                        <Typography>
                            <form onSubmit={(e, value) => this.handleSubmitSongVersionNote(e, value)}>
                                <Grid container
                                      spacing={1}
                                      sx={{
                                          marginTop: 2,
                                          marginBottom: 1
                                      }}
                                      direction="row">
                                    <Grid item xs={4}>
                                        <TextField
                                            fullWidth
                                            required
                                            id="text-field"
                                            placeholder="Enter Timestamp"
                                            value={this.state.latestVersionNotes.timestamp}
                                            variant="outlined"
                                            onChange={((e) => this.handleNoteTimeStampUpdate(e))}
                                        />
                                    </Grid>
                                    {/*<Grid item xs={2}>*/}
                                    {/*    <Box textAlign={'center'} justifyContent={'center'}>*/}
                                    {/*        <Typography>*/}
                                    {/*            {this.state.latestVersionNotes.timestamp}*/}
                                    {/*        </Typography>*/}
                                    {/*    </Box>*/}
                                    {/*</Grid>*/}
                                    <Grid item xs={8}>
                                        <TextField
                                            fullWidth
                                            required
                                            id="outlined-basic"
                                            placeholder="Add a new note"
                                            value={this.state.latestVersionNotes.notes}
                                            variant="outlined"
                                            onChange={((e) => this.handleNoteChange(e))}
                                        />
                                    </Grid>
                                </Grid>
                                <Button
                                    variant={"contained"}
                                    type="submit"
                                    sx={{
                                        marginTop: 2,
                                        marginBottom: 2
                                    }}
                                >Submit Note</Button>
                            </form>
                        </Typography>

                        <Typography
                            variant="body2"
                            sx={{
                                marginTop: 2,
                            }}>
                            <AudioPlayer
                                layout="stacked"
                                customProgressBarSection={['CURRENT_TIME', 'PROGRESS_BAR', 'DURATION']}
                                customControlsSection={['MAIN_CONTROLS']}
                                src={this.state.songAudioFileURL}
                                onPlay={e => console.log("onPlay")}
                                onPause={(e) => this.handlePauseEvent(e)}
                            />
                        </Typography>
                    </CardContent>
                </Card>
            )
        } else {
            return <div> LOADING data </div>
        }
    }
}

export default withAuth0(SongDetail);