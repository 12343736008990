import {createTheme} from "@mui/material/styles";

const landingPageBreakpoints = {
    values: {
        xs: 0,
        sm: 850,
        md: 1150,
        lg: 1550
    },
}

const appPageBreakpoints = {
    values: {
        xs: 0,
        sm: 700,
        md: 1150,
        lg: 1550
    },
}

export const lighttheme = createTheme({
    palette: {
        primary: {
            main: '#eeeeee'
        },
        secondary: {
            main: '#000000'
        },
        white: {
            main: "#FFFFFF"
        }
    },
    landingPageBreakpoints,
    typography: {
        h1: {
            fontSize: '5rem',
            fontWeight: 700,
            fontFamily: `'Manrope', 'sans-serif'`,
            [`@media only screen and (min-width: 10px)`]: {
                fontSize: '2rem',
            },
            [`@media only screen and (min-width: 500px)`]: {
                fontSize: '3rem',
            },
            [`@media only screen and (min-width: 700}px)`]: {
                fontSize: '4rem',
            },
            [`@media only screen and (min-width: 1000px)`]: {
                fontSize: '5rem',
            },
        },
        h2: {
            fontSize: '1.5rem',
            fontWeight: 300,
            [`@media only screen and (min-width: 0px)`]: {
                fontSize: '1rem',
            },
            [`@media only screen and (min-width: 500px)`]: {
                fontSize: '1.5rem',
            },
            [`@media only screen and (min-width: ${landingPageBreakpoints.values.sm}px)`]: {
                fontSize: '1.5rem',
            },
        }
    }
});

export const darktheme = createTheme({
    palette: {
        mode: "dark",
        primary: {
            main: '#000000'
        },
        secondary: {
            main: '#eeeeee'
        },
        white: {
            main: "#FFFFFF"
        }
    },
    appPageBreakpoints
});