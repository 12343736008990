import * as React from "react";
import Dropzone from "react-dropzone";
import Box from "@mui/material/Box";

export class FileDropZone extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            files: []
        }
    }

    /**
     * callback for when the files have been dropped
     * @param acceptedFiles
     */
    onDrop(acceptedFiles) {
        // set our internal state
        this.setState({
            files: acceptedFiles
        });

        // if there's some callback function from the container component, call it
        if(this.props.onDrop) {
            this.props.onDrop(acceptedFiles);
        }
    }

    /**
     * using the current state of the dropzone, change the styling appropriately
     * @param props
     * @returns {string}
     */
    getDropZoneColor(props) {
        console.log("grabbing color! with props", props);
        if (props.isDragAccept) {
            return '#00e676';
        }
        if (props.isDragReject) {
            return '#ff1744';
        }
        if (props.isFocused) {
            return '#2196f3';
        }
        return '#eeeeee';
    }

    render() {
        return (
            <Dropzone onDrop={(acceptedFiles) => this.onDrop(acceptedFiles)} accept={{
                'audio/mpeg': ['.mp3'],
                'audio/wav': ['.wav'],
                'audio/x-aiff': ['.aif']
            }}>
                {({getRootProps, getInputProps, isFocused, isDragAccept, isDragReject}) => (
                    <Box sx={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        textAlign: 'center',
                        padding: '20px',
                        borderWidth: 2,
                        borderRadius: 2,
                        borderColor: this.getDropZoneColor({isFocused: isFocused, isDragAccept: isDragAccept, isDragReject: isDragReject}),
                        borderStyle: 'dashed',
                        backgroundColor: '#121212',
                        color: '#bdbdbd',
                        outline: 'none',
                        transition: 'border .24s ease-in-out',
                        marginBottom: 4,
                        [`@media only screen and (max-width: 700px)`]: {
                            display: 'none',
                        },
                    }}>
                        <section>
                            <div {...getRootProps({isFocused, isDragAccept, isDragReject})}>
                                <input {...getInputProps()} />
                                <p>Drag 'n' drop, or click to select files to upload</p>
                            </div>
                        </section>
                    </Box>
                )}
            </Dropzone>
        )
    }
}