import React from "react";
import {darktheme} from "../Utilities/MUIThemes";
import CssBaseline from "@mui/material/CssBaseline";
import AppHeader from "./AppHeader";
import {ThemeProvider} from "@mui/material/styles";
import {Container, LinearProgress} from "@mui/material";
import Typography from "@mui/material/Typography";


export class DefaultLoadingScreen extends React.Component {
    render() {
        return (
            <ThemeProvider theme={darktheme}>
                <CssBaseline />
                <Container
                    maxWidth={false}
                    sx={{
                        // backgroundColor: darktheme.palette.primary.main,
                        height: "100vh",
                        paddingRight: 1,
                        paddingLeft: 1
                    }}
                >
                    {/*<LinearProgress></LinearProgress>*/}
                    {/*<Typography variant="h5" component="div">*/}
                    {/*    Loading...*/}
                    {/*</Typography>*/}
                    {/*<AppHeader></AppHeader>*/}
                </Container>
            </ThemeProvider>
        )
    }
}