import * as React from "react";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import { EditorContent } from '@tiptap/react';
import { Editor } from '@tiptap/core'
import StarterKit from '@tiptap/starter-kit';
import TextStyle from '@tiptap/extension-text-style';
import Color from '@tiptap/extension-color';
import { SongService } from "../Services/SongService";
import AuthService from "../Services/AuthService";
import { withAuth0 } from "@auth0/auth0-react";
import Button from "@mui/material/Button";

const extensions = [StarterKit, TextStyle, Color];

const defaultEditorContent = '<br /><p>Enter your lyrics here!</p><br /><p>Or anything else that\'s on your mind :) </p><br />'

const editor = new Editor({
    // bind Tiptap to `.element`
    // element: document.querySelector('.element'),
    // register extensions
    extensions: extensions,
    // set the initial content
    content: defaultEditorContent,
    // place the cursor in the editor after initialization
    autofocus: true,
    // make the text editable (but that’s the default anyway)
    editable: true,
    // disable the loading of the default CSS (which is not much anyway)
    injectCSS: false
});

class SongLyricsCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            song: this.props.song,
            lyrics: this.props.song.lyrics
        }
    }

    componentDidMount() {
        // @TODO NICK if this component gets more complex, consider have it handle it's own loading

        // setup event listeners for the WSIWYG editor
        this.setupEditorListeners();

        // set the content
        if(this.state.lyrics) {
            editor.commands.setContent(JSON.parse(this.state.lyrics));
        } else {
            editor.commands.setContent(defaultEditorContent);
        }
    }

    /**
     * add all of the event listeners for the wsiwyg editor
     */
    setupEditorListeners() {
        editor.on('update', () => {
            console.log("updating editor: ", editor.getJSON());

            this.setState({
                lyrics: editor.getJSON()
            })
        });
    }

    /**
     * saves the stringified JSON that represents the formatted lyrics
     */
    async saveLyrics() {
        let lyrics = JSON.stringify(this.state.lyrics);

        let token = await AuthService.getTokenSilently(this.props.auth0);

        let lyricsResult = await SongService.update({
            id: this.state.song.songIdentifier,
            lyrics: lyrics,
        }, token);

        console.log("Updated lyrics with result:", lyricsResult);
    }

    getMenuBarView() {
        return (
            <div className="control-group">
                <div className="button-group-tiptap-menu">
                    Styling:
                    <button
                        onClick={() => editor.chain().focus().toggleBold().run()}
                        disabled={
                            !editor.can()
                                .chain()
                                .focus()
                                .toggleBold()
                                .run()
                        }
                        className={editor.isActive('bold') ? 'is-active' : ''}
                    >
                        Bold
                    </button>
                    <button
                        onClick={() => editor.chain().focus().toggleItalic().run()}
                        disabled={
                            !editor.can()
                                .chain()
                                .focus()
                                .toggleItalic()
                                .run()
                        }
                        className={editor.isActive('italic') ? 'is-active' : ''}
                    >
                        Italic
                    </button>
                    <button
                        onClick={() => editor.chain().focus().toggleStrike().run()}
                        disabled={
                            !editor.can()
                                .chain()
                                .focus()
                                .toggleStrike()
                                .run()
                        }
                        className={editor.isActive('strike') ? 'is-active' : ''}
                    >
                        Strike
                    </button>

                    <br/>

                    Text Size:
                    <button
                        onClick={() => editor.chain().focus().setParagraph().run()}
                        className={editor.isActive('paragraph') ? 'is-active' : ''}
                    >
                        Normal
                    </button>
                    <button
                        onClick={() => editor.chain().focus().toggleHeading({level: 2}).run()}
                        className={editor.isActive('heading', {level: 2}) ? 'is-active' : ''}
                    >
                        Medium
                    </button>
                    <button
                        onClick={() => editor.chain().focus().toggleHeading({level: 1}).run()}
                        className={editor.isActive('heading', {level: 1}) ? 'is-active' : ''}
                    >
                        Large
                    </button>
                    {/*<button*/}
                    {/*    onClick={() => editor.chain().focus().toggleBulletList().run()}*/}
                    {/*    className={editor.isActive('bulletList') ? 'is-active' : ''}*/}
                    {/*>*/}
                    {/*    Bullet List*/}
                    {/*</button>*/}

                    <br/>

                    Highlight Color:
                    <button
                        onClick={() => editor.chain().focus().setColor('#00FF00').run()}
                        // className={editor.isActive('textStyle', {color: '#958DF1'}) ? 'is-active' : ''}
                    >
                        Green
                    </button>

                    <br/>

                    Clear Formatting:
                    <button onClick={() => {
                        editor.chain().focus().unsetAllMarks().run();
                        editor.chain().focus().clearNodes().run();
                    }}>
                        Clear Formatting
                    </button>
                </div>
            </div>
        )
    }

    render() {
        return (
            <Card>
                <CardContent>
                    <Typography>
                        Lyric Scratchpad:
                        <br/>
                        <br/>
                        {this.getMenuBarView()}
                        <br/>
                        <EditorContent editor={editor}></EditorContent>
                        <br/>
                        <Button
                            id={"savelyrics-button"}
                            variant="contained"
                            disableElevation={true}
                            sx={{
                                textTransform: "lowercase"
                            }}
                            onClick={() => this.saveLyrics()}
                        >
                            Save
                        </Button>

                    </Typography>
                </CardContent>
            </Card>
        )
    }
}

export default withAuth0(SongLyricsCard);

/*
    extra buttons for future consideration

    <button
        onClick={() => editor.chain().focus().toggleCode().run()}
        disabled={
            !editor.can()
                .chain()
                .focus()
                .toggleCode()
                .run()
        }
        className={editor.isActive('code') ? 'is-active' : ''}
    >
        Code
    </button>

 */