import React from "react";
import { Container, Box } from "@mui/material";
import Grid from '@mui/system/Unstable_Grid';
import { withRouter } from './withRouter';
import { ThemeProvider } from '@mui/material/styles';
import SSScreenshotPreview from '../assets/ss-screenshot-all-songs-preview.png';
import { LandingPageHeader } from './LandingPageHeader';
import { lighttheme } from "../Utilities/MUIThemes";
import Typography from "@mui/material/Typography";

class LandingPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {}

    render() {
        return (
            <ThemeProvider theme={lighttheme}>
                {/*<CssBaseline />*/}
                <Container
                    maxWidth={false}
                    sx={{
                        backgroundColor: lighttheme.palette.primary.main,
                        height: "100vh"
                    }}
                >
                    <LandingPageHeader />
                    <Grid container spacing={2} sx={{
                        [`@media only screen and (min-width: 300px)`]: {
                            marginTop: 6,
                        },
                        [`@media only screen and (min-width: 700px)`]: {
                            marginTop: 8,
                        },
                        [`@media only screen and (min-width: 1000px)`]: {
                            marginTop: 14,
                        },
                    }}>
                        <Grid xs={12} textAlign="center" spacing={2}
                        sx={{
                            marginTop: 0,
                            marginBottom: 0,
                            [`@media only screen and (min-width: 500px)`]: {
                                marginBottom: 2
                            },
                            [`@media only screen and (min-width: 1000px)`]: {
                                marginBottom: 0
                            },
                        }}>
                            <Typography variant={"h1"}>
                                get&nbsp;organized. get&nbsp;inspired.
                            </Typography>
                        </Grid>
                        <br />
                        <Grid xs={12}
                          textAlign="center"
                          spacing={2}
                          sx={{
                              marginTop: 2,
                              marginBottom: 3,
                              [`@media only screen and (min-width: 200px)`]: {
                                  marginTop: 0,
                                  marginBottom: 6,
                              },
                                [`@media only screen and (min-width: 700px)`]: {
                                    marginTop: 0,
                                    marginBottom: 18,
                                },
                              [`@media only screen and (min-width: 1000px)`]: {
                                  marginTop: 2,
                                  marginBottom: 14
                              },
                              [`@media only screen and (min-width: 1300px)`]: {
                                  marginTop: 2,
                                  marginBottom: 10
                              },
                            }}
                        >
                            <Typography variant={"h2"}>
                                Seamlessly&nbsp;manage&nbsp;your&nbsp;music&nbsp;files, jot&nbsp;down&nbsp;song&nbsp;notes,<br />
                                and&nbsp;streamline&nbsp;your&nbsp;creative&nbsp;process. All&nbsp;in&nbsp;one&nbsp;place.
                            </Typography>
                        </Grid>
                        <Grid xs={12} textAlign="center" sx={{
                            marginTop: 2
                        }}>
                            <Box
                                component="img"
                                sx={{
                                    // height: 600,
                                    // width: 1070,
                                    width: '100%',
                                    [`@media only screen and (min-width: 500px)`]: {
                                        width: '90%'
                                    },
                                    [`@media only screen and (min-width: 1000px)`]: {
                                        width: '80%'
                                    }
                                }}
                                alt="Sounds and Sights All Songs View Screenshot"
                                src={SSScreenshotPreview}
                            />
                        </Grid>
                    </Grid>
                </Container>
            </ThemeProvider>
        )
    }
}

export default withRouter(LandingPage);