import React from "react";
import { withAuth0 } from "@auth0/auth0-react";
import Button from "@mui/material/Button";
import Fingerprint from '@mui/icons-material/Fingerprint';

class LoginButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        return (
            <Button
                id="login-button"
                variant="contained"
                disableElevation={true}
                sx={{
                    textTransform: "lowercase"
                }}
                onClick={() => this.props.auth0.loginWithRedirect({
                    scope: 'openid profile email offline_access'
                })}>
                <Fingerprint />
                &nbsp;
                Log In
            </Button>
        )
    }
}

export default withAuth0(LoginButton);